import React, { useRef, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import Loginimage from "./images/bg.jpg";
import OrangeStarsLogoForLogin from "./OrangeStarsLogoForLogin";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (emailRef.current.value === "unihealth-otsuka" && passwordRef.current.value === "otsuka@2022") {
        history.push("/dashboard")
        localStorage.setItem("credentials", JSON.stringify({"username": emailRef.current.value, "password": passwordRef.current.value }))
      }
      else {
        setError("*Invalid Credentials")
        setTimeout(() => {
          setError("")
        }, 2500);
      }
    } catch {

      setError("Failed to log in please check your credentials !!");
    }
    setLoading(false);
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <img src={Loginimage} width="100%" height="100%" alt="background login image" />
          </div>
          <div className="col-lg-6 background-white">
            <div className="row">
              {/* <OrangeStarsLogoForLogin /> */}
              <div className="col-sm-12 col-md-8 col-lg-12 mx-auto login-margin">
                {/* <div className="card border1 shadow-lg card-signin my-5 login-card-round"> */}
                <div>
                  <div className="card-body">
                    <div id="alert1">
                      {error && <div style={{ color: "red" }}>{error}</div>}
                    </div>
                    <h1 className="card-title text-center">Sign In</h1>
                    <form className="form-signin" onSubmit={handleSubmit}>

                      <div class="form-label-group material-textfield">
                        <input
                          type="text"
                          ref={emailRef}
                          className="border1 shadow-sm form-control"
                          placeholder=""
                          required />
                        <label htmlFor="inputText">User Id</label>
                      </div>
                      <br />
                      <div className="form-label-group material-textfield">
                        <input
                          type="password"
                          id="password"
                          ref={passwordRef}
                          className="border1 shadow-sm form-control"
                          placeholder=" "
                          required
                        />
                        <label htmlFor="inputPassword">Password</label>
                      </div>
                      {/* <div className="w-100 text-right mt-3">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div>  */}
                      <div className="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                      </div>

                      <button style={{ backgroundColor: "#2196f3", color: "white" }} className="border1 btn btn-lg btn-block text-uppercase shadow login-button" type="submit">
                        Sign in
                      </button>
                    </form>
                    {/* <p className="text-center my-4">
                  Don't have an account? <Link to="/signup">Register</Link>
                </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export var COLORS = {
   
    white: '#fff',
    black: '#000',

    // overall background color (fcf5ed)
    background_color: '#f5f5f9',
    background_color_test_bar: '#fcf5ed',

    // Navbar 
    primary_navbar_background_color: '#fff',
    navbar_buttons_color: '',
    navbar_button_text_color: '#000',
    navbar_button_background_highlight: '#2196f3',
    navbar_button_text_color_highlight:'#fff',

    //Footer 
    primary_footer_background_color: '#1E3B55',
    secondary_footer_background_color: '#1E3B55',
    footer_text_color: '#fff',
  }

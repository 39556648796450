import React, { useState } from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaMediumM } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { facebook, medium, instagram, linkedin } from "../utils";
import { COLORS } from "../color";

import "../css/style.css";
export default function Footer() {
  return (
    <div className="card text-center footer1"
    ref={(el) => {
      if (el) {
        el.style.setProperty('background-color', COLORS.secondary_footer_background_color);
      }
    }}
    >
      {/* <div className="card-body">
        
      </div> */}

      <div className="card-footer text-muted footer2"
       ref={(el) => {
        if (el) {
          el.style.setProperty('background-color', COLORS.secondary_footer_background_color);
        }
      }}>

        <button type="button" className="btn btn-xl bg-new5 ">
          <a target="_blank" href={facebook}>
            <div className="form-group navIcon">
              <FaFacebookF size="20px" color="white" />
            </div>
          </a>
        </button>
        {/* <button type="button" className="btn btn-xl bg-new7">
          <a target="_blank" href={medium}>
            <div className="form-group navIcon">
              <FaMediumM size="20px" color="white" />
            </div>
          </a>
        </button> */}
        <button type="button" className="btn btn-xl bg-new6">
          <a
            target="_blank"
            href={instagram}
          >
            <div className="form-group navIcon ">
              <FaInstagram size="22px" color="white" />
            </div>
          </a>
        </button>
        <button type="button" className="btn btn-xl bg-new4">
          <a target="_blank" href={linkedin}>
            <div className="form-group navIcon ">
              <FaLinkedinIn size="20px" color="white" />
            </div>
          </a>
        </button>
        
        <p className="changecolor"
        ref={(el) => {
          if (el) {
            el.style.setProperty('color', COLORS.footer_text_color);
          }
        }}>
          Copyright 2021 © NirogGyan All rights reserved | <a className="footer_term" href="https://niroggyan.com/terms" target="_blank">Privacy Policy </a> |
          <a className="footer_term" href="https://niroggyan.com/terms" target="_blank">Terms & Conditions </a>
          
        </p>

      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import '../css/style.css'
import '../css/hover.css'
import { ReactComponent as Anemia } from '../images/cards/anemia.svg';
import { ReactComponent as Urine } from '../images/cards/urine.svg';
import { ReactComponent as Cancer } from '../images/cards/Cancer_Profile_icon.svg';
import { ReactComponent as B12 } from '../images/cards/B12.svg';
import { ReactComponent as Kidney } from '../images/cards/kidney.svg';
import { ReactComponent as Lipids } from '../images/cards/lipids.svg';
import { ReactComponent as Liver } from '../images/cards/liver.svg';
import { ReactComponent as Thyroid } from '../images/cards/thyroid.svg';
import { ReactComponent as VitD } from '../images/cards/VitD.svg';
import { ReactComponent as Diabetes } from '../images/cards/diabetes.svg';
import { ReactComponent as BodyScreening } from '../images/cards/body_screening_concern.svg';
import { ReactComponent as Bmi }  from '../images/cards/bmi_image.svg';

import ReactTooltip from 'react-tooltip';
import { AiFillInfoCircle } from "react-icons/ai";

const DashCard = (props) => {

    let record_concern_profile_names = []

    // console.log("record_concern_profile_names: ", record_concern_profile_names)

    let the_data = localStorage.getItem("concern_area_data")

    if(the_data) {
        // console.log("=====>", JSON.parse(the_data))
        let the_profiles = JSON.parse(the_data)
        for (let i=0; i<the_profiles.length; i++) {
            if(the_profiles[i].percent >= 25) {
                record_concern_profile_names.push(the_profiles[i].profile_name)
            }
        }
    }

    console.log("record_concern_profile_names\n", record_concern_profile_names)

    return (
        <div className="container padd">
            {(record_concern_profile_names.length > 0) && <h2 className="text-center pt-4 pb-2 mb-4" id="title">Concern Areas <AiFillInfoCircle data-tip="Below are the areas of concern for the selected group of employees" className="mb-1 insight-i-icon" /></h2>}
            <div className="row">
                {record_concern_profile_names.includes("Kidney Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card rounded shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Kidney</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Kidney disease causes toxins to accumulate in the blood</p>
                            <Kidney className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>} 
                {record_concern_profile_names.includes("Lipid Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card rounded shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Lipids</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Lipids out of range is a major reason for heart disease</p>
                            <Lipids className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Thyroid Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card rounded shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Thyroid</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Thyroid disease makes a person feel tired and drained of energy</p>
                            <Thyroid className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Liver Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Liver</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Untreated liver disease can cause liver failure and is life threatening</p>
                            <Liver className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Diabetes Monitoring") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card rounded shadow round_test">
                        <h5 className="text-center card-header bolded text-uppercase">Diabetes</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Diabetes affects the nervous system, causing damage to organs</p>
                            <Diabetes className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Vitamin Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card rounded shadow round_test">
                        <h5 className="text-center card-header bolded text-uppercase">Vitamin D</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Vitamin D deficiency causes brittle bones</p>
                            <VitD className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Vitamin Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card rounded shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Vitamin B12</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Vitamin B12 deficiency causes dizziness and headaches</p>
                            <B12 className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Anemia Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Anemia</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Anemia causes low oxygen saturation in the blood, damaging internal organs</p>
                            <Anemia className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Urinalysis") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Urine</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Frequent urge to urinate without being able to pass much urine.</p>
                            <Urine className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {record_concern_profile_names.includes("Cancer Profile") && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Cancer</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">Screening can help doctors find and treat several types of cancer early.</p>
                            <Cancer className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}
                {/* {props.include_in_card[11] == 1 && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">Body Screening</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span">These are simple examinations to check the health of your ears, eyes and chest.</p>
                            <BodyScreening className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}  
                {props.include_in_card[12] == 1 && <div className="mb-4 col-lg-6 col-md-5 col-sm-6 flexcard" >
                    <div className="card shadow round_test">
                    <h5 className="text-center card-header bolded text-uppercase">BP & BMI</h5>
                        <div className="card-body text-center hover-container hover-card">
                            <p class = "span"> Body mass index (BMI) is a measure of body fat based on height and weight that applies to adult men and women.</p>
                            <Bmi className="mb-4 img-opacity" />
                            <h6 className="text-center bolded"></h6>
                        </div>
                    </div>
                </div>}     */}
            </div>
            <ReactTooltip />
        </div>
    );
}

export default DashCard;

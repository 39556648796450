import React, { Component, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import '../css/style.css'
import drink_water from "../images/drink_water.jpg";
import exercise from "../images/exercise.svg"
import no_smoking from "../images/no_smoking.svg"
import eat_healthy from "../images/eat_healthy.svg"
import manage_stress from "../images/manage_stress.png"
import sleep from "../images/sleep.png"
import maintain_weight from "../images/maintain_weight.png"
import sunlight from "../images/sunlight.png"
import D_suppliment from "../images/D_suppliment.jpg"
import weight_loss from "../images/weight_loss.svg"
import meat_egg from "../images/meat_egg.svg"
import sugar_free from "../images/sugar_free.svg"
import fat_free from "../images/fat_free_food.svg"
import no_drinking from "../images/no_drinking.svg"

import D_rich_food from "../images/D_rich_food.svg"
import ReactTooltip from 'react-tooltip';
import { AiFillInfoCircle } from "react-icons/ai";
import { HiOutlineLightBulb } from "react-icons/hi"
import { COLORS } from "../color";
import HealthReport from "../reportpdf/Orange_Stars-Health-Report.pdf"
import { insightsData } from "./insightsData";
import { Card, Container } from "react-bootstrap";
import test_data from '../../tests_data.json'
// import ImageLocator from "../ImageLocator";
import ReactApexChart from 'react-apexcharts'
import theimage from '../images/vitaminD.svg'
import Stack from 'react-bootstrap/Stack';

import '../css/insightsCss.css'
import { useHistory } from "react-router-dom";


const Insights = () => {

  let history = useHistory()

  // credentials check
  useEffect(() => {

    const loginData = localStorage.getItem("credentials")
    if (loginData) {
      //      
    } else {
      history.push("/login")
    }
  }, [])

  // pie chart
  let allTheData = {
    // series: [60, 40],
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: '40%' // controlls thickness of donut
          }
        }
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
      },
      colors: ["#DB4437", "#0F9D58"],
      dataLabels: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        enabled: true,
        // formatter: function (val) {
        //     return val + "%"
        // },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex] + "%"
        },
        textAnchor: 'end',
      },
      legend: {
        show: false
      },
      labels: ["Abnormal", "Normal"],
      responsive: [{
        breakpoint: 480,
      }]
    },
  };


  const additionalSectionsHandler = (the_data) => {

    return (
      <div>

        {the_data.map((each_section, idx) => (
          <div style={{ display: "flex" }}>

            <Card className="p-3" style={{ width: "100%", borderRadius: "12px", marginBottom: "25px" }}>
              <div>
                {each_section.image === "no_image"
                  ?
                  ""
                  :
                  // <ImageLocator imageName={each_section.image} />

                  <img src={`/moreImages/${each_section.image}`} alt={each_section.image} style={{ width: "40px", height: "40px", marginRight: "5px" }} />

                }

                <span style={{ fontWeight: "550" }}>{each_section.heading}</span>

                <div
                  className="custom-grid"
                  style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", width: "100%" }}>

                  {each_section.suggestions.map((each_point, the_id) => (

                    <div
                      key={the_id}
                      className="custom-grid-item"
                      style={{
                        width: "30%",
                        // width: 
                        // each_section.suggestions.length === 1 ? "100%"
                        // :
                        // each_section.suggestions.length > 1 && each_section.suggestions.length <= 3 ? "30%"
                        // :
                        // "30%",
                        textAlign: "justify",
                        display: "flex",
                        border: "1px dashed #B3B3B3",
                        padding: "10px 15px 10px 15px",
                        marginRight: "10px",
                        marginLeft: "10px",
                        marginTop: each_section.suggestions.length > 3 ? "20px" : "",
                        borderRadius: "8px"
                      }}>

                      <div>
                        {each_point.image === "no_image" ? ""
                          :
                          <img
                            src={`/moreImages/${each_point.image}`}
                            alt={each_point.image}
                            style={{
                              width: "30px", height: "30px", marginRight: "10px",
                              position: "relative",
                              top: "5%",
                              transform: "translateY(-5%)"
                            }}
                          />
                        }
                      </div>
                      <div>
                        <span style={{
                          fontSize: "13px",
                          color: "#6a6a6a"
                        }}>
                          {each_point.heading}
                        </span>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </Card>

          </div>
        ))}

      </div>
    )
  }

  return (
    <div>
      <div style={{ backgroundColor: COLORS.background_color, height: "100%" }}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 text-center">
            <Navbar page="insights" />
          </div>
        </div>
        <div className="row headings">
          {/* text-center */}

          {/* <h3 style={{paddingTop: "3vh", paddingLeft: "5vw"}}>
              Insights
            </h3> */}

          <div className="col-lg-12 text-center">
            <h1 className="bold-heading">Know what's up with your people</h1>
            <h3>Deep dive into data-driven health insights</h3>
            <span style={{ color: "#7a7e85" }}>
              <p>Important parameters found abnormal for your employees</p>
            </span>
          </div>

          {test_data.map((each, idx) => (

            <Card style={{ padding: "20px 20px 0px 20px" }} className="mt-4">
              <div className="d-flex">
                <div style={{ width: "85%" }}>
                  <span style={{ fontSize: "16px", fontWeight: "bold", color: "#2F3750" }}>{each.display_name}</span>
                  <span style={{ color: "#6a6a6a" }}>
                    <p>{each.test_description}</p>
                  </span>
                </div>

                <ReactApexChart
                  options={allTheData.options}
                  series={[each.abnormal, each.normal]}
                  type="donut"
                  width={"180"}
                />

              </div>

              <div style={{ borderTop: "1px dashed #B3B3B3", margin: "15px 0px 30px 0px" }} />

              {/* Tests Additional Section Logic */}
              {each.additional_sections.length === 0 ? "" : additionalSectionsHandler(each.additional_sections)}

              {/* Profile Tips Section Logic */}

              {each.profileData.additional_sections.length === 0 ? "" : additionalSectionsHandler(each.profileData.additional_sections)}

            </Card>
          ))}

        </div>

        {/* New table horizontal scroll */}


      </div>

      <div className="row">
        <Footer />
      </div>
      <ReactTooltip />
    </div>
  );
};

export default Insights;